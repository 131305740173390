import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../common/api.service';
import { AuthResult } from '../login/authresult.model';

@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.scss'],
})
export class MfaComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private router: Router
  ) {}
  authResult: AuthResult;
  mfaImage: string;
  mfaToken: string;
  totp: string = '';
  disableSubmitTOTP: boolean = true;
  username: string;
  account: string;
  groups: string[] = [];
  jwtToken: string;
  showError: boolean = false;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.mfaImage = params.mfaImage !== 'undefined' ? params.mfaImage : '';
      this.mfaToken = params.mfaToken;
      this.account = params.account;
      this.username = params.username;
    });
  }

  submitQRCode() {
    this.mfaImage = '';
  }

  onTOTPChange(e) {
    this.totp = e;
    this.totp.length === 6
      ? (this.disableSubmitTOTP = false)
      : (this.disableSubmitTOTP = true);
  }

  submitMFACode() {
    this.apiService
      .validateTOTPCode(this.username, this.account, this.totp, this.mfaToken)
      .then((rsp) => {
        rsp.success === false
          ? (this.showError = true)
          : (this.showError = false);
        this.jwtToken = rsp.jwtToken;
        this.groups = rsp.payload.groups ? rsp.payload.groups : [];
      });
  }

  loginToQC(group) {
    this.apiService
      .loginToQC(`${group.url}/idp-api/public/public/auth/token`, this.jwtToken)
      .subscribe(() => {
        window.location.href = `${group.url}/liquid`;
      });
  }

  changePassword() {
    this.router.navigate([
      'change-password',
      {
        username: this.username,
        account: this.account,
      },
    ]);
  }

  backToLogin() {
    this.router.navigate(['login']);
  }
}
